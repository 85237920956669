import React from 'react';
import '../styles/aboutme.css';

export default function AboutMe() {
    return (
        <section id="about" className="about-me-root">
            <blockquote className="about-me-quote">
                <p>" Nothing will work unless you do "</p>
            </blockquote>
            <div className="about-me-content">
                <p>
                    I'm <strong>HAMMANI Mohamed</strong>, a Big Data and Data Science Engineer based in Canada, with expertise in data integration, machine learning, and generating insights from datasets.
                </p>
                <p>
                    I’ve worked on projects ranging from deep learning models to creating dashboards and pipelines, solving real-world problems.
                </p>
                <p>
                    I hold an engineering degree in Big Data and Data Science and am currently pursuing a Master's in AI and Data Science.
                </p>
                <p>
                    Passionate about learning and driven by curiosity, I strive to master skills and adapt to challenges. Let's embark on this journey together.
                </p>
            </div>
        </section>
    );
};
