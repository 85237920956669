import AboutMe from "../AboutMe";
import HeroSection from "../HeroSection";
import Expertise from "../Expertise";
import Certificates from "../Certificates";
import Career from "../Career";
import ContactMe from "../ContactMe";
import Footer from "../Footer";

export default function Home () {
    return (
        <>
        <HeroSection/>
        <Expertise/>
        <Career/>
        <Certificates/>
        <AboutMe/>
        <ContactMe/>
        <Footer/>
        </>
    )
}