import data from "../../data/index.json";
import "../css/Career.css";

export default function Career() {
  return (
    <section className="Professional-xp--section" id="testimonial">
      {/* Header Section */}
      <div className="Professional-xp--container-box">
        <div className="Professional-xp--container">
          <h2 className="section--heading">Professional Experience</h2>
        </div>
        <div>
          <a
            href="https://github.com/Simon-Einar?tab=repositories"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn btn-github">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 0C5.373 0 0 5.373 0 12c0 5.303 3.438 9.799 8.207 11.387.6.11.793-.26.793-.577v-2.17c-3.338.726-4.042-1.61-4.042-1.61-.546-1.387-1.333-1.757-1.333-1.757-1.09-.745.082-.73.082-.73 1.205.086 1.84 1.236 1.84 1.236 1.07 1.834 2.809 1.304 3.494.997.107-.775.418-1.305.762-1.605-2.665-.305-5.466-1.332-5.466-5.933 0-1.31.468-2.381 1.236-3.221-.124-.304-.535-1.527.117-3.176 0 0 1.008-.322 3.3 1.23a11.451 11.451 0 013.006-.404c1.02.004 2.048.138 3.006.404 2.29-1.553 3.297-1.23 3.297-1.23.654 1.649.243 2.872.12 3.176.77.84 1.236 1.911 1.236 3.221 0 4.611-2.804 5.625-5.475 5.922.43.371.814 1.102.814 2.221v3.293c0 .321.19.694.801.576C20.565 21.796 24 17.3 24 12c0-6.627-5.373-12-12-12z"
                  fill="currentColor"
                />
              </svg>
              Visit My GitHub
            </button>
          </a>
        </div>
      </div>

      {/* Professional Experience Cards */}
      <div className="Professional-xp--section--container">
        {data?.portfolio?.length > 0 ? (
          data.portfolio.map((item, index) => (
            <article key={index} className="Professional-xp--section--card">
              <div className="Professional-xp--section--img">
                <img
                  loading="lazy"
                  src={item.src || "placeholder.jpg"}
                  alt={item.title || "Portfolio Item"}
                />
              </div>
              <div className="Professional-xp--section--card--content">
                <h3 className="Professional-xp--section--title">
                  {item.title || "Title Not Available"}
                </h3>
                <p className="Professional-xp--date">
                  {item.date || "Date not available."}
                </p>
                <p className="Professional-xp--text-md">
                  {item.description || "Description not available."}
                </p>
                
                <div className="portfolio--link">
                  <a
                    href={item.url || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    {item.link || "View Company"}
                  </a>
                  <a
                    href={item.repo || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    View Project
                  </a>
                </div>
              </div>
            </article>
          ))
        ) : (
          <p>No professional experience data available.</p>
        )}
      </div>

      {/* Universities Section */}
      <div className="Professional-xp--container-box">
        <div className="Professional-xp--container">
          <h2 className="section--heading">Universities Attended</h2>
        </div>
      </div>
      <div className="Professional-xp--section--container">
        {data?.universities?.length > 0 ? (
          data.universities.map((item, index) => (
            <article key={index} className="Professional-xp--section--card">
              <div className="Professional-xp--section--img">
                <img
                  loading="lazy"
                  src={item.src || "placeholder.jpg"}
                  alt={item.title || "University Logo"}
                />
              </div>
              <div className="Professional-xp--section--card--content">
                <h3 className="Professional-xp--section--title">
                  {item.title || "University Name"}
                </h3>
                <p className="Professional-xp--date">
                  {item.date || "Date not available."}
                </p>
                <p className="Professional-xp--text-md">
                  {item.description || "Description not available."}
                </p>
               
                <div className="portfolio--link">
                  <a
                    href={item.url || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    {item.link || "Visit Site"}
                  </a>
                </div>
              </div>
            </article>
          ))
        ) : (
          <p>No university data available.</p>
        )}
      </div>
    </section>
  );
}
