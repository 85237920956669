import React, { useState } from "react";
import data from "../../data/index.json";
import "../css/Expertise.css";

export default function Expertise() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [flippedCards, setFlippedCards] = useState({});
  const [activeCategory, setActiveCategory] = useState(null); // Tracks active sub-category

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.Skills.length);
    setFlippedCards({});
    setActiveCategory(null);
  };
  
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.Skills.length) % data.Skills.length);
    setFlippedCards({});
    setActiveCategory(null);
  };
  
  const visibleCards = [
    data.Skills[currentIndex],
    data.Skills[(currentIndex + 1) % data.Skills.length],
    data.Skills[(currentIndex + 2) % data.Skills.length],
  ];
  

  const toggleCardFlip = (index) => {
    setFlippedCards((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    setActiveCategory(null); // Reset active category when flipping
  };


  return (
    <section className="skills--section--background" id="mySkills">
      <div className="skills--section">
        <div className="portfolio--container">
          <h2 className="skills--section--heading">My Expertise</h2>
        </div>

        <div className="carousel--container">
          <button className="carousel--button prev" onClick={prevSlide}>
            &#8592;
          </button>

          <div className="carousel--slide">
            {visibleCards.map((card, index) => {
              const isFlipped = flippedCards[index];
              return (
                <div
                  key={card.id}
                  className={`skills--section--card ${isFlipped ? "flipped" : ""}`}
                >
                  {!isFlipped ? (
                    <div className="card--front">
                      <div className="skills--section--img">
                        <img src={card.src} alt={card.title} />
                      </div>
                      <div className="skills--section--card--content">
                        <h3 className="skills--section--title">{card.title}</h3>
                        <p className="skills--section--description">{card.description}</p>
                        <button
                          className="show-skills-button"
                          onClick={() => toggleCardFlip(index)}
                        >
                          Show Skills
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="card--back">
                      <h3 className="skills--section--title">{card.title}</h3>
                      <div className="skills--categories">
                        {Object.entries(card.technologies).map(([category, items]) => (
                          <div
                            key={category}
                            className={`skills--category ${
                              activeCategory === category ? "open" : ""
                            }`}
                            onClick={() =>
                              setActiveCategory(
                                activeCategory === category ? null : category
                              )
                            }
                          >
                            <h4 className="skills--category--title">{category}</h4>
                            {activeCategory === category && (
                              <div>
                                {Array.isArray(items) ? (
                                  <ul>
                                    {items.map((item, i) => (
                                      <li key={i}>{item}</li>
                                    ))}
                                  </ul>
                                ) : typeof items === "object" ? (
                                  Object.entries(items).map(
                                    ([subCategory, subItems]) => (
                                      <div key={subCategory}>
                                        <strong>{subCategory}:</strong>
                                        {Array.isArray(subItems) ? (
                                          <ul>
                                            {subItems.map((subItem, j) => (
                                              <li key={j}>{subItem}</li>
                                            ))}
                                          </ul>
                                        ) : (
                                          <p>{subItems}</p>
                                        )}
                                      </div>
                                    )
                                  )
                                ) : (
                                  <p>{items}</p> // Handle strings or other primitives
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <button
                        className="back-button"
                        onClick={() => toggleCardFlip(index)}
                      >
                        Go Back
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <button className="carousel--button next" onClick={nextSlide}>
            &#8594;
          </button>
        </div>
      </div>
    </section>
  );
}
