import React from 'react';
import '../styles/FooterSection.css';

export default function FooterSection() {
    return (
        <footer className="mobile-footer">
            <div className="footer-content">
                <ul className="footer-links">
                    <li><a href="#HomePage-mobile">Home</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#professionalExperience">Projects</a></li>
                    <li><a href="#universities">Education</a></li>
                </ul>
                <p>&copy; {new Date().getFullYear()} Hammani Mohamed. All Rights Reserved.</p>
            </div>
        </footer>
    );
}
