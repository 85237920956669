import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { isMobile } from 'react-device-detect'; // Import react-device-detect
import ReactGA from "react-ga4"; // Import react-ga4

// Desktop Components
import Navbar from './Pages/Home/Navbar';
import Home from './Pages/Home/Homescreen';

// Mobile Components
import MobileHeader from './Mobile/js/Header';
import MobileHome from './Mobile/js/Homescreen';

// Initialize Google Analytics
ReactGA.initialize("G-XR2ZXDMDP1");

// Custom hook to track page views
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
};

function App() {
  return (
    <div className="App">
      <Router>
        <PageTracker /> {/* Add Page Tracking */}
        <div>
          {/* Conditional Rendering for Navbar/Header */}
          {isMobile ? <MobileHeader /> : <Navbar />}

          {/* Conditional Routes for Desktop and Mobile */}
          <Routes>
            <Route path="/" element={isMobile ? <MobileHome /> : <Home />} />
            <Route path="*" element={<div>404 Not Found</div>} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

// Component to handle page tracking
const PageTracker = () => {
  usePageTracking();
  return null; // This component only handles tracking
};

export default App;
