import "../styles/contact.css";
export default function ContactMe() {
    return (
      <section id="Contact">
        <div className="contact--heading--container">
          <h2 className="sections--heading-contact">Get In Touch</h2>
        </div>
        <form className="contact--form--container" method="post" action="https://formspree.io/f/xnnayrkr">
          <div className="container">
            <label htmlFor="first-name" className="contact-label">
              <span>First Name</span>
              <input
                type="text" 
                className="contact-input"
                name="first-name"
                id="first-name"
                required
              />
            </label>
            <label htmlFor="last-name" className="contact-label">
              <span>Last Name</span>
              <input
                type="text"
                className="contact-input"
                name="last-name"
                id="last-name"
                required
              />
            </label>
          </div>
          <div className="container">
            <label htmlFor="email" className="contact-label">
              <span>Email</span>
              <input
                type="email"
                className="contact-input"
                name="email"
                id="email"
                required
              />
            </label>
            <label htmlFor="phone-number" className="contact-label">
              <span>Phone Number</span>
              <input
                type="number"
                className="contact-input"
                name="phone-number"
                id="phone-number"
              />
            </label>
          </div>
          <label htmlFor="message" className="contact-label">
            <span>Message</span>
            <textarea
              className="contact-input"
              id="message"
              name="message"
              rows="5"
              placeholder="Type your message..."
              required
            />
          </label>
          <div className="submit--btn-container">
            <button className="contact--form-btn">Submit</button>
          </div>
        </form>
      </section>
    );
}