import FooterSection from '../Footer'
import HomeSection from '../HomePage';
import ProfessionalExp from '../ProfessionalExp';
import SkillsTechnologies from '../skills';
import University from '../University';
import Contact from '../contact';
import AboutMe from '../aboutme';

export default function MobileHome () {
    return (
        <>
            <HomeSection/>
            <SkillsTechnologies/>
            <ProfessionalExp/>
            <University/>
            <AboutMe/>
            <Contact/>
            <FooterSection/>
        </>
    )
}


 