import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import '../styles/ProfessionalExp.css';
import { FaBriefcase, FaFolder } from 'react-icons/fa';
import internshipsData from '../../data/index.json';

export default function ProfessionalExp() {
    const internships = internshipsData.portfolio_mobile;
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    // Swipe handlers
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () =>
            setCurrentIndex((prevIndex) =>
                prevIndex === internships.length - 1 ? 0 : prevIndex + 1
            ),
        onSwipedRight: () =>
            setCurrentIndex((prevIndex) =>
                prevIndex === 0 ? internships.length - 1 : prevIndex - 1
            ),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true, // Allow swipe with mouse
    });

    return (
        <section
            id="professionalExperience"
            className="expertise-section professional-exp-root"
            {...swipeHandlers} // Attach swipe handlers
        >
            <h2 className="section-title">Professional Experiences</h2>
            <div className="internships-container">
                {internships.map((internship, index) => (
                    <div
                        key={internship.id}
                        className={`internship-card ${index === currentIndex ? 'active' : ''}`}
                    >
                        <img 
                            src={internship.src} 
                            alt={`${internship.title} logo`} 
                            className="internship-image"
                        />
                        <h3 
                            className="company-name"
                        >
                            {internship.title}
                        </h3>
                        <h4 
                            className="internship-date"
                        >
                            {internship.date}
                        </h4>
                        <p 
                            className="internship-position"
                        >
                            {internship.description}
                        </p>
                        <div className="button-container">
                            <a 
                                href={internship.url} 
                                className="internship-link" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <FaBriefcase style={{ marginRight: '8px' }} /> {internship.link}
                            </a>
                            <a 
                                href={internship.repo} 
                                className="internship-link project-link" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <FaFolder style={{ marginRight: '8px' }} /> View Project
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            <div className="dots-container">
                {internships.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => handleDotClick(index)}
                    ></span>
                ))}
            </div>
        </section>
    );
}
