import React, { useEffect } from 'react';
import '../styles/HomePage.css';
import { Link } from 'react-scroll';

export default function HomePage() {
    useEffect(() => {
        document.body.classList.add('home-page');
        return () => {
            document.body.classList.remove('home-page'); // Cleanup on unmount
        };
    }, []);

    return (
        <section 
        id="HomePage-mobile"
        className="mobile-home-section mobile-home-section-root">
            <div className="home-content">
                <div className="home-image-mobile">
                    <img src="./img/hero-mobile.png" alt="Home Illustration" />
                </div>

                {/* Social Media Icons */}
                <div className="social-icons">
                    <a 
                        href="https://github.com/Simon-Einar?tab=repositories" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="github-btn"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                            fill="white"
                        >
                            <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.207 11.385.6.113.793-.258.793-.577v-2.243c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.757-1.333-1.757-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.236 1.839 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.419-1.305.762-1.605-2.665-.304-5.466-1.334-5.466-5.933 0-1.311.469-2.381 1.236-3.221-.124-.303-.536-1.521.117-3.176 0 0 1.008-.322 3.3 1.23a11.54 11.54 0 013.006-.404c1.02.004 2.048.138 3.006.404 2.292-1.552 3.3-1.23 3.3-1.23.653 1.655.241 2.873.118 3.176.77.84 1.236 1.91 1.236 3.221 0 4.609-2.804 5.625-5.475 5.922.43.371.823 1.104.823 2.222v3.293c0 .321.192.694.801.576C20.565 22.092 24 17.594 24 12.297c0-6.627-5.373-12-12-12z" />
                        </svg>
                    </a>

                    <a 
                        href="https://www.linkedin.com/in/hammani-mohamed-24a020209/" 
                        target="_blank" 
                        rel="noreferrer" 
                        className="linkedin-btn"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                            fill="white"
                        >
                            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.268c-.966 0-1.75-.783-1.75-1.75s.784-1.75 1.75-1.75c.967 0 1.75.783 1.75 1.75s-.783 1.75-1.75 1.75zm13.5 11.268h-3v-5.604c0-1.337-.027-3.063-1.866-3.063-1.868 0-2.154 1.46-2.154 2.966v5.701h-3v-10h2.881v1.367h.041c.401-.759 1.379-1.559 2.838-1.559 3.035 0 3.596 2 3.596 4.604v5.588z" />
                        </svg>
                    </a>

                    <a
                        href="https://www.instagram.com/simo_hammani_/"
                        target="_blank"
                        rel="noreferrer"
                        className="instagram-btn"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                            fill="white"
                        >
                            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.333 3.608 1.308.975.975 1.246 2.242 1.308 3.608.058 1.266.07 1.646.07 4.85s-.012 3.584-.07 4.85c-.062 1.366-.333 2.633-1.308 3.608-.975.975-2.242 1.246-3.608 1.308-1.266.058-1.646.07-4.85.07s-3.584-.012-4.85-.07c-1.366-.062-2.633-.333-3.608-1.308-.975-.975-1.246-2.242-1.308-3.608-.058-1.266-.07-1.646-.07-4.85s.012-3.584.07-4.85c.062-1.366.333-2.633 1.308-3.608.975-.975 2.242-1.246 3.608-1.308 1.266-.058 1.646-.07 4.85-.07zm0-2.163C8.755 0 8.333.012 7.052.07 5.772.127 4.587.37 3.513 1.445 2.44 2.52 2.197 3.705 2.14 4.985.982 6.287.982 6.705.982 12c0 5.295.012 5.713.07 6.998.057 1.281.3 2.466 1.375 3.54 1.073 1.074 2.258 1.317 3.54 1.375 1.285.057 1.703.07 6.998.07 5.295 0 5.713-.012 6.998-.07 1.281-.057 2.466-.3 3.54-1.375 1.074-1.073 1.317-2.258 1.375-3.54.057-1.285.07-1.703.07-6.998 0-5.295-.012-5.713-.07-6.998-.057-1.281-.3-2.466-1.375-3.54-1.073-1.074-2.258-1.317-3.54-1.375C17.713.012 17.295 0 12 0zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.162 6.162 6.162 6.162-2.759 6.162-6.162-2.759-6.162-6.162-6.162zm0 10.162c-2.213 0-4.015-1.802-4.015-4.015S9.787 7.97 12 7.97s4.015 1.802 4.015 4.015-1.802 4.015-4.015 4.015zm6.406-10.845c-.796 0-1.441-.645-1.441-1.441 0-.796.645-1.441 1.441-1.441.796 0 1.441.645 1.441 1.441 0 .796-.645 1.441-1.441 1.441z" />
                        </svg>
                    </a>
                </div>

                <div className="home-buttons">
                    <a href="./pdf/resume-HM.pdf" download className="button download-btn">Download Resume</a>
                    <Link
                           
                            to="Contact"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                    <button className="button contact-btn">Contact Me</button>
                    </Link>
                </div> 
            </div>
            
        </section>
    );
}
