import { Link } from "react-scroll";
import "../css/HeroSection.css";
import { FaLinkedin, FaGithub } from "react-icons/fa";

export default function HeroSection() {
    return (
        <section id="heroSection" className="hero--section--background">
            <div className="hero--section">
                <div className="hero--content">
                    <h1 className="hero--title">
                        Data Scientist & <br /> Big Data Engineer
                    </h1>
                    <p className="hero--description">
                        "I transform data into insights and insights into innovation."
                    </p>
                    <div className="hero--buttons">
                        <a href="./pdf/resume-HM.pdf" download="HAMMANI_Mohamed_Resume.pdf" aria-label="Download Resume">
                            <button className="hero--button">Download Resume</button>
                        </a>
                        <Link 
                            to="Contact" 
                            spy={true} 
                            smooth={true} 
                            offset={-70} 
                            duration={500}
                        >
                            <button className="hero--button contact">Contact Me</button>
                        </Link>
                    </div>
                </div>
                <div className="hero--image-wrapper">
                    <img 
                        className="hero--image" 
                        src="./img/hero.svg" 
                        alt="Illustration of a data scientist" 
                    />
                    <div className="hero--social-icons">
                        <a href="https://www.linkedin.com/in/hammani-mohamed-24a020209/" target="_blank" rel="noopener noreferrer" className="hero--social-icon" aria-label="LinkedIn">
                            <FaLinkedin />
                        </a>
                        <a href="https://github.com/Simon-Einar?tab=repositories" target="_blank" rel="noopener noreferrer" className="hero--social-icon" aria-label="GitHub">
                            <FaGithub />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}
