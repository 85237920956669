import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import '../styles/University.css';
import universitiesData from '../../data/index.json';

export default function University() {
    const { universities } = universitiesData;
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => setCurrentIndex((prevIndex) => (prevIndex === universities.length - 1 ? 0 : prevIndex + 1)),
        onSwipedRight: () => setCurrentIndex((prevIndex) => (prevIndex === 0 ? universities.length - 1 : prevIndex - 1)),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <section
        id="universities"
            className="university-section"
            {...swipeHandlers}
        >
            <h2 className="section-title">Universities</h2>
            <div className="university-container">
                {universities.map((university, index) => (
                    <div
                        key={university.id}
                        className={`university-card ${index === currentIndex ? 'active' : ''}`}
                    >
                        <img
                            src={university.src}
                            alt={`${university.title} logo`}
                            className="university-image"
                        />
                        <h3 className="university-title">{university.title}</h3>
                        <p className="university-date">{university.date}</p> {/* Added this line */}
                        <p className="university-description">{university.description}</p>
                        <a
                            href={university.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="university-link"
                        >
                            {university.link}
                        </a>
                    </div>
                ))}
            </div>
            <div className="dots-container">
                {universities.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => handleDotClick(index)}
                    ></span>
                ))}
            </div>
        </section>
    );
}
