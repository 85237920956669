import React, { useState } from 'react';
import '../styles/Header.css';
import { Link } from 'react-scroll';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <header className="mobile-header mobile-header-root">
            <div className="header-container">
                <div className="navbar--brand">
                    <Link
                        onClick={closeMenu}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to="HomePage-mobile"
                        className="navbar--name"
                    >
                        <img 
                            className="navbar--logo" 
                            src="./img/logo1.png" 
                            alt="HAMMANI Mohamed" 
                        /> 
                    </Link>
                </div>
                <button className="menu-icon" onClick={toggleMenu}>
                    ☰
                </button>
            </div>
            <nav>
                <ul className={`nav-links ${isOpen ? "active" : ""}`}>
                    <li>
                        <Link
                            onClick={closeMenu}
                            to="HomePage-mobile"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={closeMenu}
                            to="skills"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            Skills
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={closeMenu}
                            to="professionalExperience"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            Professional Experience
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={closeMenu}
                            to="universities"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            Universities
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={closeMenu}
                            to="about"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            About Me
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={closeMenu}
                            to="Contact"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            Contact Me
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
