import '../styles/skills.css';
import React, { useState, useEffect } from 'react';
import skillsData from '../../data/skills-mobile.json';
import { FaDatabase, FaCodeBranch, FaServer, FaStream, FaChartLine, FaPython, FaEye, FaBrain, FaCloud, FaLaptopCode, FaDraftingCompass } from 'react-icons/fa';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

export default function SkillsTechnologies() {
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!skillsData || !Object.keys(skillsData).length) {
            setError('No skills data available. Please check your data source.');
        }
    }, []);

    const toggleCategory = (categoryIndex) => {
        setExpandedCategory(expandedCategory === categoryIndex ? null : categoryIndex);
    };

    const categoryIcons = {
        "DataManipulation&Cleaning": <FaPython className="icon" />, 
        "DataVisualization": <FaEye className="icon" />, 
        "MachineLearning": <FaBrain className="icon" />, 
        "DataStorage": <FaDatabase className="icon" />, 
        "BigDataTechnologies": <FaServer className="icon" />, 
        "DataPipelines&ETL": <FaStream className="icon" />, 
        "DevOps&Automation": <FaCodeBranch className="icon" />, 
        "FrameworksAndLibraries": <FaLaptopCode className="icon" />, 
        "UserInterfaceFrameworks": <FaDraftingCompass className="icon" />
    };

    return (
        <div
        id="skills" 
        className="skills-page">
            <h1 className="page-title">Skills</h1>
            {error ? (
                <p className="error-message">{error}</p>
            ) : (
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    className="skills-swiper"
                >
                    {/* Data Science & Analysis Section */}
                    <SwiperSlide>
                        <section className="skills-technologies-section">
                            <h2 className="section-title static-color">Data Science & Analysis</h2>
                            {Object.entries(skillsData["DataScience&Analysis"]).map(([category, items], index) => (
                                <div key={`data-science-${index}`} className="card-container">
                                    <div className="title-card" onClick={() => toggleCategory(`data-science-${index}`)}>
                                        <div className="title-card-content">
                                            {categoryIcons[category] || <FaChartLine className="icon" />}
                                            <span className="title-card-title">{category.replace(/([A-Z])/g, ' $1').trim()}</span>
                                        </div>
                                    </div>
                                    {expandedCategory === `data-science-${index}` && (
                                        <div className="technologies-card">
                                            {typeof items === 'object' && !Array.isArray(items) ? (
                                                Object.entries(items).map(([subcategory, subItems], subIndex) => (
                                                    <div key={subIndex} className="subcategory-container">
                                                        <h4 className="subcategory-title">{subcategory}</h4>
                                                        <ul className="no-bullet">
                                                            {subItems.map((subItem, idx) => (
                                                                <li key={idx} className="item">{subItem}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ))
                                            ) : (
                                                <ul className="no-bullet">
                                                    {items.map((item, idx) => (
                                                        <li key={idx} className="item">{item}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </section>
                    </SwiperSlide>
                    {/* Data Engineering Section */}
                    <SwiperSlide>
                        <section className="skills-technologies-section">
                            <h2 className="section-title static-color">Data Engineering</h2>
                            {Object.entries(skillsData.DataSkills).map(([category, details], index) => (
                                <div key={`data-engineering-${index}`} className="card-container">
                                    <div className="title-card" onClick={() => toggleCategory(`data-engineering-${index}`)}>
                                        <div className="title-card-content">
                                            {categoryIcons[category] || <FaStream className="icon" />}
                                            <span className="title-card-title">{category.replace(/([A-Z])/g, ' $1').trim()}</span>
                                        </div>
                                    </div>
                                    {expandedCategory === `data-engineering-${index}` && (
                                        <div className="technologies-card">
                                            {Object.entries(details).map(([subcategory, items], subIndex) => (
                                                <div key={subIndex} className="subcategory-container">
                                                    <h4 className="subcategory-title">{subcategory.replace(/([A-Z])/g, ' $1').trim()}</h4>
                                                    {Array.isArray(items) ? (
                                                        <ul className="no-bullet">
                                                            {items.map((item, idx) => (
                                                                <li key={idx} className="item">{item}</li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <p className="item">{items}</p>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </section>
                    </SwiperSlide>
                    {/* Frontend Development Section */}
                    <SwiperSlide>
                        <section className="skills-technologies-section">
                            <h2 className="section-title static-color">Frontend Development</h2>
                            {Object.entries(skillsData["FrontendDevelopment"]).map(([category, items], index) => (
                                <div key={`frontend-${index}`} className="card-container">
                                    <div className="title-card" onClick={() => toggleCategory(`frontend-${index}`)}>
                                        <div className="title-card-content">
                                            {categoryIcons[category] || categoryIcons["FrontendDevelopment"]}
                                            <span className="title-card-title">{category.replace(/([A-Z])/g, ' $1').trim()}</span>
                                        </div>
                                    </div>
                                    {expandedCategory === `frontend-${index}` && (
                                        <div className="technologies-card">
                                            <ul className="no-bullet">
                                                {items.map((item, idx) => (
                                                    <li key={idx} className="item">{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </section>
                    </SwiperSlide>
                </Swiper>
            )}
        </div>
    );
}
