import React, { useState } from "react";
import data from "../../data/index.json";
import "../css/Certificates.css";

export default function Certificates() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const certifications = data.Certificats;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % certifications.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + certifications.length) % certifications.length);
  };

  const visibleCertifications = [
    certifications[currentIndex],
    certifications[(currentIndex + 1) % certifications.length],
    certifications[(currentIndex + 2) % certifications.length],
  ];

  return (
    <section className="certifications-section" id="MyPortfolio">
      <div className="certifications-container">
        <h2 className="section-heading">My Certificates</h2>
        <div className="slideshow-container">
          <button
            className="arrow-button left-arrow"
            onClick={prevSlide}
            aria-label="Previous certificates"
          >
            &#8592;
          </button>
          <div className="certifications-display">
            {visibleCertifications.map((cert, index) => (
              <div className="certification-card" key={cert.id}>
                <img
                  src={cert.src}
                  alt={`Certification: ${cert.title}`}
                  className="cert-icon"
                />
                <h3>{cert.title}</h3>
                <p>{cert.description}</p>
              </div>
            ))}
          </div>
          <button
            className="arrow-button right-arrow"
            onClick={nextSlide}
            aria-label="Next certificates"
          >
            &#8594;
          </button>
        </div>
      </div>
    </section>
  );
}
