import "../css/ContactMe.css";

export default function ContactMe() {
  return (
    <section id="Contact" className="contact-section">
      <div className="contact-header">
        <h2 className="contact-title">Get In Touch</h2>
        <p className="contact-subtitle">I'd love to hear from you!</p>
      </div>
      <form
        className="contact-form"
        method="post"
        action="https://formspree.io/f/xnnayrkr"
      >
        <div className="form-group">
          <div className="form-item">
            <label htmlFor="first-name" className="form-label">
              First Name
            </label>
            <input
              type="text"
              id="first-name"
              name="first-name"
              className="form-input"
              placeholder="Enter your first name"
              required
            />
          </div>
          <div className="form-item">
            <label htmlFor="last-name" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              id="last-name"
              name="last-name"
              className="form-input"
              placeholder="Enter your last name"
              required
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-item">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-input"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-item">
            <label htmlFor="phone-number" className="form-label">
              Phone Number
            </label>
            <input
              type="tel"
              id="phone-number"
              name="phone-number"
              className="form-input"
              placeholder="Enter your phone number"
            />
          </div>
        </div>
        <div className="form-item">
          <label htmlFor="message" className="form-label">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            className="form-input"
            placeholder="Type your message..."
            rows="6"
            required
          ></textarea>
        </div>
        <div className="form-submit">
          <button type="submit" className="submit-button">
            Submit
          </button>
        </div>
      </form>
    </section>
  );
}
